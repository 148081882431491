import {AppAction} from '../../app/AppMessage';
import {IAction} from '../../core/actions/Actions';
import {
  TFirebaseAnnouncement,
  TFirebaseChallengeGroup,
  TFirebaseCompCounts,
  TFirebaseGroup,
  TFirebaseLeaderboard,
  TFirebaseQuestion,
  TFirebaseUser,
} from '../firestore/FirestoreClientInterfaces';
import {
  TUserCompletedAchievement,
  TUserCompletedChallenge,
  TUserCompletedComp,
  TUserCompletedMiniLeague,
  TUserPassedThreshold,
} from '../firestore/FirestoreInterfaces';
import {
  IChallengeOverlayProps,
  ICompareOverlayProps,
  IEnterCompOverlayProps,
  IGetNativeAppGroupOverlayProps,
  IGetUpgradePromptOverlayProps,
  IGroupSettingsOverlayProps,
  ILeaderboardDetailsOverlayProps,
  INotificationPromptOverlayProps,
  IOverlay,
  IPlayerProfileOverlayProps,
  ISimpleMessageFromAppOverlayProps,
  ISimpleMessageOverlayProps,
  IStartupOverlayProps,
  IUploadImageOverlayProps,
  OverlayType,
} from './OverlayInterfaces';

export const getLoadingOverlay = (): IOverlay => ({
  type: OverlayType.LOADING,
  transitionDuration: 150,
});

export const getSimpleMessageOverlay = (
  props: Omit<ISimpleMessageOverlayProps, 'type' | 'transitionDuration'>,
): ISimpleMessageOverlayProps => ({
  type: OverlayType.SIMPLE_MESSAGE,
  transitionDuration: 150,
  ...props,
  dismissable: props.dismissable ?? true,
});

export const getSimpleMessageOverlayFromApp = (props: {
  title: string;
  message: string;
  onCloseRoutine?: AppAction;
  onActionRoutine?: AppAction;
  closeButtonText?: string;
  actionButtonText?: string;
  dismissable?: boolean;
}): ISimpleMessageFromAppOverlayProps => ({
  type: OverlayType.SIMPLE_MESSAGE,
  transitionDuration: 150,
  ...props,
  dismissable: props.dismissable ?? true,
});

export const getNativeAppUpdateOverlay = (
  dismissable: boolean = false,
  title: string = 'App Update Required',
  message: string = 'Mixnpik is updating regularly to bring you the best predictions game possible.\n\nUnfortunately you have a version of the app installed that is too old and will need to update.',
): IOverlay => {
  const overlay = getSimpleMessageOverlay({
    dismissable,
    title,
    message,
    actionButtonText: 'Update Mixnpik',
  });
  overlay.type = OverlayType.NATIVE_APP_UPDATE_REQUIRED;
  return overlay;
};

export const getShareOverlay = (): IOverlay => ({
  type: OverlayType.SHARE,
  transitionDuration: 150,
});

export const getUploadProfileImageOverlay = (): IOverlay => ({
  type: OverlayType.UPLOAD_PROFILE_IMAGE,
  transitionDuration: 150,
});

export const getUploadImageOverlay = (
  uploadId: string,
  targetDimensions: {
    width: number;
    height: number;
    crop: boolean;
    isCircleCrop?: boolean;
  },
  remoteFolderPath: string,
  onDeleteAction: () => IAction<any>,
  onSuccessAction?: (remoteUrl: string) => IAction<any>,
): IUploadImageOverlayProps => ({
  type: OverlayType.UPLOAD_IMAGE,
  transitionDuration: 150,
  uploadId,
  targetDimensions,
  remoteFolderPath,
  onDeleteAction,
  onSuccessAction,
});

export const getLeaderboardDetailsOverlay = (
  leaderboardConfig: TFirebaseLeaderboard,
): ILeaderboardDetailsOverlayProps => ({
  type: OverlayType.LEADERBOARD_DETAILS,
  transitionDuration: 150,
  leaderboardConfig,
});

export const getNativeAppChallengeOverlay = (): IOverlay => ({
  type: OverlayType.GET_NATIVE_APP_CHALLENGE,
  transitionDuration: 150,
});

export const getNativeAppMissedQuestionOverlay = (): IOverlay => ({
  type: OverlayType.GET_NATIVE_APP_MISSED_QUESTION,
  transitionDuration: 150,
});
export const getNativeAppGroupOverlay = (
  groupId: string,
): IGetNativeAppGroupOverlayProps => ({
  type: OverlayType.GET_NATIVE_APP_GROUP,
  transitionDuration: 150,
  groupId,
});

export const getNativeAppPromptOverlay = (): IOverlay => ({
  type: OverlayType.GET_NATIVE_APP_PROMPT,
  transitionDuration: 150,
});

export const getUpgradePromptOverlay = (
  daysRemaining: number,
): IGetUpgradePromptOverlayProps => ({
  type: OverlayType.GET_UPGRADE_PROMPT,
  transitionDuration: 150,
  daysRemaining,
});

export const getEnterCompOverlay = (
  props: Omit<IEnterCompOverlayProps, 'type' | 'transitionDuration'>,
): IEnterCompOverlayProps => ({
  type: OverlayType.ENTER_COMP,
  transitionDuration: 150,
  ...props,
});

export const getGroupSettingsOverlay = (
  group: TFirebaseGroup,
  onDeleteGroup: () => void,
): IGroupSettingsOverlayProps => ({
  type: OverlayType.GROUP_SETTINGS,
  transitionDuration: 150,
  group,
  onDeleteGroup,
});

export const getPlayerProfileOverlay = (
  user: TFirebaseUser,
  vendor: string,
  compId?: string,
  questions?: TFirebaseQuestion[],
  counts?: TFirebaseCompCounts,
): IPlayerProfileOverlayProps => ({
  type: OverlayType.PLAYER_PROFILE,
  transitionDuration: 150,
  user,
  vendor,
  compId,
  questions,
  counts,
});

export const getCompareOverlay = (
  userA: TFirebaseUser,
  userB: TFirebaseUser,
  vendor: string,
  compId?: string,
  questions?: TFirebaseQuestion[],
  counts?: TFirebaseCompCounts,
): ICompareOverlayProps => ({
  type: OverlayType.COMPARE,
  transitionDuration: 150,
  userA,
  userB,
  vendor,
  compId,
  questions,
  counts,
});

export const getChallengeDetailsOverlay = (
  group: TFirebaseChallengeGroup,
  tabIndex: number = 1,
): IChallengeOverlayProps => ({
  type: OverlayType.CHALLENGE_DETAILS,
  transitionDuration: 150,
  group,
  tabIndex,
});

export const getNotificationsPromptOverlay = (
  settingsPrompt: boolean,
): INotificationPromptOverlayProps => ({
  type: OverlayType.NOTIFICATIONS_PROMPT,
  transitionDuration: 150,
  settingsPrompt,
});

export const getStartupOverlay = (
  user: TFirebaseUser,
  achievements: TUserCompletedAchievement[],
  passedThresholds: TUserPassedThreshold[],
  comps: TUserCompletedComp[],
  challenges: TUserCompletedChallenge[],
  miniLeagues: TUserCompletedMiniLeague[],
  announcement: TFirebaseAnnouncement | null,
): IStartupOverlayProps => ({
  type: OverlayType.STARTUP,
  transitionDuration: 150,
  user,
  achievements,
  passedThresholds,
  comps,
  challenges,
  miniLeagues,
  announcement,
});
